import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import '@contentful/forma-36-react-components/dist/styles.css'
import VideoAssetFinder from './components/VideoAssetFinder'
import Taglist from './components/Taglist'
import VideoAssetList from './components/VideoAssetList'
import CardsEditor from './components/CardsEditor'
import PanelsEditor from './components/PanelsEditor'
import DateRangeEditor from './components/DateRangeEditor'
import PlatformSelector from './components/PlatformSelector'
import TrailerUploader from './components/TrailerUploader'
import TrailerUploaderV2 from './components/TrailerUploaderV2'
import ShowcaseTargetSelector from './components/ShowcaseTargetSelector'
import NewsList from './components/NewsList'
import Playlist from './components/Playlist'
import SuggestedEpisode from './components/SuggestedEpisode'
import BoolWithDefault from './components/BoolWithDefault'
import RadioWithDefault from './components/RadioWithDefault'
import SelectWithDefault from './components/SelectWithDefault'
import CometFieldSelector from './components/CometFieldSelector'
import LabelAssigner from './components/LabelAssigner'
import CDPSelector from './components/CDPSelector'
import 'whatwg-fetch'
import 'react-datepicker/dist/react-datepicker.css'
import VideoAssetFinderV2 from './components/VideoAssetFinderV2'

/* disable usage of contentful-ui-extensions because of webpack issues with their bundled code
  that code is included through a script-tag in index.html for now.
*/
// import ExtensionContext from './ExtensionContext'
// import { init } from 'contentful-ui-extensions-sdk' // causes webpack issues.

const Routes = () => (
  <Router>
    <Switch>
      <Route path="/videoassetfinder" component={VideoAssetFinder} />
      <Route path="/videoassetfinderV2" component={VideoAssetFinderV2} />
      <Route path="/taglist" exact component={Taglist} />
      <Route path="/videoAssetList" exact component={VideoAssetList} />
      <Route path="/daterange" exact component={DateRangeEditor} />
      <Route path="/cardseditor" exact component={CardsEditor} />
      <Route path="/platformselector" exact component={PlatformSelector} />
      <Route path="/panelseditor" exact component={PanelsEditor} />
      <Route path="/traileruploader" exact component={TrailerUploader} />
      <Route path="/traileruploaderv2" exact component={TrailerUploaderV2} />
      <Route path="/showcasetargetselector" exact component={ShowcaseTargetSelector} />
      <Route path="/newslist" exact component={NewsList} />
      <Route path="/playlist" exact component={Playlist} />
      <Route path="/suggestedepisode" exact component={SuggestedEpisode} />
      <Route path="/boolwithdefault" exact component={BoolWithDefault} />
      <Route path="/radiowithdefault" exact component={RadioWithDefault} />
      <Route path="/selectwithdefault" exact component={SelectWithDefault} />
      <Route path="/cometfieldselector" exact component={CometFieldSelector} />
      <Route path="/labelassigner" exact component={LabelAssigner} />
      <Route path="/cdpselector" exact component={CDPSelector} />
    </Switch>
  </Router>
)
export default ReactDOM.render(<Routes />, document.getElementById('root'))
// export default init(sdk => {
//   console.log('initting!', sdk)
//   ReactDOM.render(
//     <ExtensionContext.Provider value={{ sdk, loading: false }}>
//       <Routes sdk={sdk} />
//     </ExtensionContext.Provider>,
//     document.getElementById('root')
//   )
// })
